import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../Title"
import { Link, useLocation } from "react-router-dom"
import { Box, Button, IconButton, InputAdornment, Stack, TableSortLabel, TextField } from "@mui/material"
import Series from "../../types/Series"
import SeriesFormDialog from "./SeriesFormDialog"
import { useDeleteSeriesMutation } from "../../services/seriesApi"
import ColumnSort from "../../types/ColumnSort"
import { visuallyHidden } from '@mui/utils'
import { currencyFormatOptions, defaultDateTimeOptions } from "../../app/constants"
import Protected from "../Protected"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectSeriesSearchTerm, setSeriesSearchTerm } from "../../slices/searchSlice"
import ClearIcon from '@mui/icons-material/Clear';

type SeriesGridProps = { series?:Series[], organizationId?:number, facilityId?:number, allowNewSeries?:boolean|true, showSearchBox?:boolean|true, showOrganizationInfo?:Boolean|false, showFacilityInfo?:Boolean|false, afterSave?:Function, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const SeriesGrid = (props:SeriesGridProps) => {
    const dispatch = useAppDispatch()
    const seriesSearchTerm = useAppSelector(selectSeriesSearchTerm)

    const {series,organizationId,facilityId,allowNewSeries,showSearchBox,showOrganizationInfo,showFacilityInfo,afterSave,handleSearch,sort,handleSort} = props
    const [openDialog,setOpenDialog] = useState(false)
    const [deleteSeries] = useDeleteSeriesMutation()
    const [clonedSeries, setClonedSeries] = useState({} as unknown as Series)
    const [searchTerm, setSearchTerm] = useState<string|undefined>(seriesSearchTerm)

    const onSearchSeries = (evt:any) => {
        dispatch(setSeriesSearchTerm(evt.currentTarget.value.toLowerCase()))
        setSearchTerm(evt.currentTarget.value.toLowerCase())
    }

    const onKeyUp = (evt:any) => {
        if (evt.key === 'Enter') {
            handleSearch && handleSearch(searchTerm)
        }
    }

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }

    const clearSearchTerm = () => {
        dispatch(setSeriesSearchTerm(''))
        setSearchTerm('')
    }

    const handleDeleteSeries = async (series:Series) => {
        // TODO: confirmation dialog
        await deleteSeries(series)
        afterSave && afterSave()
    }    

    const handleCloneSeries = (series:Series) => {
        setClonedSeries({
            seriesName: series.seriesName,
            description: series.description,
            price: series.price,
            organizationId: series.organizationId,
            organizationName: series.organizationName,
            facilityId: series.facilityId,
            facilityName: series.facilityName,
            startTime: series.startTime,
            endTime: series.endTime,
            daysOfWeek: series.daysOfWeek,
            showSessionsForSeriesOnly: series.showSessionsForSeriesOnly
        } as Series)

        openFormDialog()
    }
    
    const handleNewSeries = () => {
        setClonedSeries({ organizationId, facilityId } as unknown as Series)

        openFormDialog()
    }

    const location = useLocation();

    useEffect(() => {
        handleSearch && handleSearch(searchTerm)
    },[searchTerm])    
    
    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Series</Title>
                <Stack direction="row" justifyContent="flex-end">
                    { showSearchBox &&                 
                    <TextField 
                        size="small" 
                        style={{ marginRight: 15 }} 
                        name="series-search" 
                        id="series-search" 
                        onChange={onSearchSeries} 
                        onKeyUpCapture={onKeyUp} 
                        placeholder="Search" 
                        value={searchTerm}
                        InputProps={{endAdornment: (
                            <InputAdornment position="end">
                                {searchTerm ? (<IconButton 
                                children={<ClearIcon/>}
                                edge='end'
                                size='small'
                                onClick={clearSearchTerm}/>) : (<></>)}
                            </InputAdornment>
                        )}}></TextField> }
                    { allowNewSeries && 
                        <Protected 
                            anyRole={["IceTime Admin","Organization Admin","Facility Manager","Scheduler"]}
                            //protectedElement={<Button color="primary" variant="contained" onClick={handleNewSeries}>New Series</Button>}
                        ><Button color="primary" variant="contained" onClick={handleNewSeries}>New Series</Button></Protected>
                    }
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "seriesname"}
                            direction={sort?.sortBy === "seriesname" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "seriesname",
                                sortDirection: sort.sortBy === "seriesname" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "seriesname" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        { showOrganizationInfo ? 
                    <TableCell>
                    <TableSortLabel
                        active={ sort?.sortBy === "organizationname"}
                        direction={sort?.sortBy === "organizationname" ? sort?.sortDirection : 'asc'}
                        onClick={() => handleSort && handleSort({
                            sortBy: "organizationname",
                            sortDirection: sort.sortBy === "organizationname" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                        }) }>
                            Organization
                            {sort?.sortBy === "organizationname" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>) : null}
                    </TableSortLabel>
                    </TableCell> 
                        : <></> }
                        { showFacilityInfo ? 
                   <TableCell>
                   <TableSortLabel
                       active={ sort?.sortBy === "facilityname"}
                       direction={sort?.sortBy === "facilityname" ? sort?.sortDirection : 'asc'}
                       onClick={() => handleSort && handleSort({
                           sortBy: "facilityname",
                           sortDirection: sort.sortBy === "facilityname" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                       }) }>
                           Facility
                           {sort?.sortBy === "facilityname" ? (
                           <Box component="span" sx={visuallyHidden}>
                               {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                           </Box>) : null}
                   </TableSortLabel>
                   </TableCell> 
                        : <></> }
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "date"}
                            direction={sort?.sortBy === "date" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "date",
                                sortDirection: sort.sortBy === "date" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Dates
                                {sort?.sortBy === "date" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "time"}
                            direction={sort?.sortBy === "time" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "time",
                                sortDirection: sort.sortBy === "time" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Times
                                {sort?.sortBy === "time" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "usersavecount"}
                            direction={sort?.sortBy === "usersavecount" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "usersavecount",
                                sortDirection: sort.sortBy === "usersavecount" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Number of Saves
                                {sort?.sortBy === "usersavecount" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>                        
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {series && series.length > 0 ? ([...series].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/series/${row.id}`}>{row.seriesName}</Link>
                        </TableCell>
                        { showOrganizationInfo ? 
                        <TableCell>
                            <Link to={`/organizations/${row.organizationId}`}>{row.organizationName}</Link>
                        </TableCell> : <></> }
                        { showFacilityInfo ? 
                        <TableCell>
                            <Link to={`/facilities/${row.facilityId}`}>{row.facilityName}</Link>
                        </TableCell> : <></> }                                                  
                        <TableCell>{new Date(row.startDate + ' ' + row.startTime).toLocaleDateString('en-US', defaultDateTimeOptions.dateOptions)} - {new Date(row.endDate + ' ' + row.endTime).toLocaleDateString('en-US', defaultDateTimeOptions.dateOptions)}</TableCell>
                        <TableCell>{new Date(row.startDate + ' ' + row.startTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)} - {new Date(row.endDate + ' ' + row.endTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)}</TableCell>
                        <TableCell>{currencyFormatOptions.USDollar.format(row.price) || '--'}</TableCell>
                        <TableCell>{row.userSaveCount || 0}</TableCell>
                        <TableCell>
                            <Stack direction="row" justifyContent="flex-end">
                                <Protected
                                    anyRole={["IceTime Admin","Organization Admin","Facility Manager","Scheduler"]}
                                ><Button sx={{m:1}} color="primary" variant="outlined" onClick={() => handleCloneSeries(row)}>Clone</Button></Protected>
                                <Protected
                                    anyRole={["IceTime Admin","Organization Admin","Facility Manager","Scheduler"]}
                                ><Button sx={{m:1}} color="error" variant="contained" data-id={row.id} onClick={() => handleDeleteSeries(row)}>Delete</Button></Protected> 
                            </Stack>    
                        </TableCell> 
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={4}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <SeriesFormDialog series={clonedSeries} open={openDialog} onClose={closeFormDialog} cloneSeries={() => handleCloneSeries(clonedSeries)} afterSave={afterSave} fullWidth={true} maxWidth="md" />
                </>}                          
        </div>
    )
}

export default SeriesGrid