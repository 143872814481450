import Dashboard from "./Dashboard";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useGetAllUsersQuery, useLazyFindUsersBySearchTermPageQuery, useLazyGetUserPageQuery } from "../services/userApi";
import UserGrid from "../components/user/UserGrid";
import { useEffect, useState } from "react";
import { pagination } from "../app/constants";
import Paged from "../types/Paged";
import User from "../types/User";
import { useAppDispatch, useAppSelector, useDebounce } from "../app/hooks";
import { GetPagedArgs } from "../app/queries";
import ColumnSort from "../types/ColumnSort";
import { selectUserPageArgs, setUserPageArgs } from "../slices/searchSlice";

const UserPage = (props:any) => {
    //const {data:users, error, isLoading} = useGetAllUsersQuery()

    const pageArgs = useAppSelector(selectUserPageArgs)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [userPage, setUserPage] = useState<Paged<User>>(undefined)
    const [getUserPage] = useLazyGetUserPageQuery()
    const [findUsersBySearchTermPage] = useLazyFindUsersBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(pageArgs.searchTerm, 500)    

    useEffect(() => {
      setIsLoading(true)
      getUsers().then(_ => setIsLoading(false))
    },[pageArgs.pageNumber,pageArgs.pageSize,pageArgs.sortBy,pageArgs.sortDirection,debouncedSearchTerm])    
    
    const fetchUserPage = async () => {
      const sp = await getUserPage(pageArgs)
      setUserPage(sp.data)
    }

    const searchUserPage = async () => {
      const sp = await findUsersBySearchTermPage(pageArgs)
      setUserPage(sp.data)
    }
    
    const getUsers = async () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        await fetchUserPage()
      } else {
        await searchUserPage()
      }
    }    

    const handleSave = () => {
      const args = {...pageArgs}
      console.log('debouncedSearchTerm: ', debouncedSearchTerm)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        args.searchTerm = debouncedSearchTerm
      }
      args.pageNumber = 1

      dispatch(setUserPageArgs(args))
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(setUserPageArgs({
        ...pageArgs,
        pageNumber: newPage+1
      }))
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setUserPageArgs({
        ...pageArgs,
        pageSize: +event.target.value,
        pageNumber: 1
      }))
    };
    
    const handleSearch = (searchTerm:string) => {
      const args = {...pageArgs, searchTerm}

      if ((pageArgs.searchTerm && pageArgs.searchTerm.length > 0) && (!searchTerm || searchTerm.length === 0)) {
        args.pageNumber = 1
        args.sortBy = 'username'
        args.sortDirection = 'asc'
      }

      dispatch(setUserPageArgs(args))
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      dispatch(setUserPageArgs({
        ...pageArgs,
        sortBy: updatedSort.sortBy,
        sortDirection: updatedSort.sortDirection,
        pageNumber: 1
      }))
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <UserGrid users={userPage ? userPage.items : []} allowNewUser={false} handleSearch={handleSearch} sort={{sortBy: pageArgs.sortBy, sortDirection: pageArgs.sortDirection}} handleSort={handleSort}></UserGrid>
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={userPage?.totalItems ?? 0}
                          rowsPerPage={pageArgs.pageSize}
                          page={pageArgs.pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                      
                    </> 
                    }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default UserPage