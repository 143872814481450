import Dashboard from "./Dashboard";
import SeriesGrid from "../components/series/SeriesGrid";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useLazyFindSeriesBySearchTermPageQuery, useLazyGetSeriesPageQuery } from "../services/seriesApi";
import { useEffect, useState } from "react";
import Paged from "../types/Paged";
import Series from "../types/Series";
import { useAppDispatch, useAppSelector, useDebounce } from "../app/hooks";
import { pagination } from "../app/constants";
import ColumnSort from "../types/ColumnSort";
import { selectSeriesPageArgs, setSeriesPageArgs } from "../slices/searchSlice";

const SeriesPage = (props:any) => {
    const pageArgs = useAppSelector(selectSeriesPageArgs)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [seriesPage, setSeriesPage] = useState<Paged<Series>>(undefined)
    const [getSeriesPage] = useLazyGetSeriesPageQuery()
    const [findSeriesBySearchTermPage] = useLazyFindSeriesBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(pageArgs.searchTerm, 500)

    const fetchSeriesPage = async () => {
      const sp = await getSeriesPage(pageArgs)
      setSeriesPage(sp.data)
    }

    const searchSeriesPage = async () => {
      const sp = await findSeriesBySearchTermPage(pageArgs)
      setSeriesPage(sp.data)
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      dispatch(setSeriesPageArgs({
        ...pageArgs,
        sortBy: updatedSort.sortBy,
        sortDirection: updatedSort.sortDirection,
        pageNumber: 1
      }))
    }

    const handleSave = async () => {
      const args = {...pageArgs}
      console.log('debouncedSearchTerm: ', debouncedSearchTerm)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        args.searchTerm = debouncedSearchTerm
      }
      args.pageNumber = 1

      dispatch(setSeriesPageArgs(args))
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(setSeriesPageArgs({
        ...pageArgs,
        pageNumber: newPage+1
      }))
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setSeriesPageArgs({
        ...pageArgs,
        pageSize: +event.target.value,
        pageNumber: 1
      }))
    };
    
    const handleSearch = (searchTerm:string) => {
      const args = {...pageArgs, searchTerm}

      if ((pageArgs.searchTerm && pageArgs.searchTerm.length > 0) && (!searchTerm || searchTerm.length === 0)) {
        args.pageNumber = 1
        args.sortBy = 'seriesname'
        args.sortDirection = 'asc'
      }

      dispatch(setSeriesPageArgs(args))
    }

    useEffect(() => {
      setIsLoading(true)
      if (!debouncedSearchTerm || debouncedSearchTerm.length === 0) {
        fetchSeriesPage().then(_ => setIsLoading(false))
      } else {
        searchSeriesPage().then(_ => setIsLoading(false))
      }
    },[debouncedSearchTerm, pageArgs.pageNumber, pageArgs.pageSize, pageArgs.sortBy, pageArgs.sortDirection])    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                      <>
                      <SeriesGrid series={seriesPage ? seriesPage.items : []} showFacilityInfo showSearchBox allowNewSeries afterSave={handleSave} handleSearch={handleSearch} sort={{sortBy: pageArgs.sortBy, sortDirection: pageArgs.sortDirection}} handleSort={handleSort} />
                      <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={seriesPage?.totalItems ?? 0}
                          rowsPerPage={pageArgs.pageSize}
                          page={pageArgs.pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />
                      </> }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default SeriesPage