import { useEffect, useState } from "react"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography, Alert, Button, Grid, Stack, TextField, Select, MenuItem, FormControl, FormHelperText, InputLabel, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material"
import { useGetAllOrganizationsQuery, useLazyGetOrganizationFacilitiesQuery } from "../../services/organizationApi"
import Series from "../../types/Series"
import { useGetAllSchedulesQuery } from "../../services/scheduleApi"
import { useCreateSeriesMutation, useGenerateSessionsMutation, useUpdateSeriesMutation } from "../../services/seriesApi"
import Facility from "../../types/Facility"
import { dayOfWeekOptions } from "../../app/constants"
import { current } from "@reduxjs/toolkit"
import { useAuthProvider } from "../../app/context"
import { useLazyGetAllFacilitiesQuery } from "../../services/facilityApi"

type SeriesFormProps = { series:Series, organizationId?:number, facilityId?:number, afterSave?:Function }
type SeriesFormValues = {
    id: number,
    seriesName:string,
    seriesNumber: string,
    description: string,
    price: number,
    organizationId:number,
    facilityId:number,
    startDate:string,
    startTime:string,
    endDate:string,
    endTime:string,
    daysOfWeek:string[],
    registrationUrl:string,
    showSessionsForSeriesOnly:boolean
}

const SeriesForm = ({series, organizationId, facilityId, afterSave}:SeriesFormProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState<any>('success')
    const [alertContent, setAlertContent] = useState('')
    const [createSeries] = useCreateSeriesMutation()
    const [updateSeries] = useUpdateSeriesMutation()
    const [generateSessions] = useGenerateSessionsMutation()
    const {data:organizations, error:orgError, isLoading:orgsLoading} = useGetAllOrganizationsQuery()
    // const {data:schedules, error:schedError, isLoading:schedulesLoading} = useGetAllSchedulesQuery()
    const [fetchOrgFacilities] = useLazyGetOrganizationFacilitiesQuery()
    const [fetchAllFacilities] = useLazyGetAllFacilitiesQuery()
    const [orgFacilities,setOrgFacilities] = useState<Array<Facility>>([])
    const [transientOrganizationId, setTransientOrganizationId] = useState<number|undefined>(undefined)
    // const [transientScheduleId, setTransientScheduleId] = useState<number|undefined>(undefined)
    const authProvider = useAuthProvider()

    const validationSchema = Yup.object().shape({
        seriesName: Yup.string().required('Name is required').max(50),
        seriesNumber: Yup.string().required('Series Number is required').max(15),
        description: Yup.string().notRequired().max(1024),
        price: Yup.number().notRequired(),
        startDate: Yup.string().required('Start date is required'),
        startTime: Yup.string().required('Start time is required'),
        endDate: Yup.string().required('End date is required'),
        endTime: Yup.string().required('End time is required'),
        daysOfWeek: Yup.array().default([]).min(1, 'At least one must be selected').required(),
        organizationId: Yup.number().required('Organization is required'),
        facilityId: Yup.number().required('Facility is required'),
        showSessionsForSeriesOnly: Yup.boolean(),
        registrationUrl: Yup.string().url().notRequired().max(255)
    })

    const mapSeriesToValues = (series:Series) : SeriesFormValues => {
        return series ? {
            id: series.id,
            seriesName: series.seriesName,
            seriesNumber: series.seriesNumber,
            description: series.description,
            price: series.price,
            startDate: series.startDate,
            startTime: series.startTime,
            endDate: series.endDate,
            endTime: series.endTime,
            daysOfWeek: series.daysOfWeek || [],
            organizationId: series.organizationId,
            facilityId: series.facilityId,
            registrationUrl: series.registrationUrl,
            showSessionsForSeriesOnly: series.showSessionsForSeriesOnly
        } satisfies SeriesFormValues : { organizationId, facilityId } as SeriesFormValues
    }

    const mapValuesToSeries = async (values:SeriesFormValues) : Promise<Series> => {
        const updateUser = await authProvider.getUser()
        const updateUsername = updateUser.email ?? updateUser.preferred_username
        return {
            id: values.id,
            seriesName: values.seriesName,
            seriesNumber: values.seriesNumber,
            description: values.description,
            price: values.price,
            startDate: values.startDate,
            startTime: values.startTime,
            endDate: values.endDate,
            endTime: values.endTime,
            daysOfWeek: values.daysOfWeek,
            organizationId: values.organizationId || organizationId || 0,
            facilityId: values.facilityId || facilityId || 0,
            showSessionsForSeriesOnly: values.showSessionsForSeriesOnly,
            registrationUrl: values.registrationUrl,
            lastModified: new Date(),
            modifiedBy: updateUsername,
            status: 'ACTIVE',
        } as Series
    }

    const formik = useFormik({
        initialValues: mapSeriesToValues(series) as SeriesFormValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    const handleGenerateSessionsClick = async () => {
        setShowAlert(false)
        setAlertContent('Generating sessions...')
        setAlertSeverity('info')
        setShowAlert(true)

        try {
        
        const updatedSeries = await mapValuesToSeries(formik.values)
        await generateSessions(updatedSeries.id)
        setAlertContent(`Sessions for ${updatedSeries.seriesName} generated successfully.`)
        setAlertSeverity('success')
        setShowAlert(true)
        afterSave && afterSave()

        } catch (error:any) {
            setAlertContent('Error generating sessions.')
            setAlertSeverity('error')
            setShowAlert(true)
        }
    }

    const handleSubmit = async (values:SeriesFormValues) => {
        setShowAlert(false)
        setAlertContent('Saving...')
        setAlertSeverity('info')
        setShowAlert(true)

        const updateFn = (series.id > 0 ? updateSeries : createSeries)
        
        try {
            let updatedSeries = await updateFn(await mapValuesToSeries(values)).unwrap()
            formik.setValues(mapSeriesToValues(updatedSeries))
            setAlertContent(`Series ${updatedSeries.seriesName} saved.`)
            setAlertSeverity('success')
            setShowAlert(true)
            afterSave && afterSave()            

        } catch (error:any) {
            formik.setErrors(error)
            setAlertContent('Error saving series.')
            setAlertSeverity('error')
            setShowAlert(true)            
        }
    }

    useEffect(() => {

        let orgId = formik.values.organizationId || organizationId

        if (orgId && orgId != transientOrganizationId) {
            fetchOrgFacilities(orgId).unwrap().then((facilities) => setOrgFacilities(facilities))
            setTransientOrganizationId(orgId)
        } else {
            fetchAllFacilities().unwrap().then((facilities) => setOrgFacilities(facilities))
        }

        // let schedId = formik.values.scheduleId || scheduleId

        // if (schedId && schedId != transientScheduleId) {
        //     const selectedSchedule = schedules?.filter(s => s.id === schedId)
        //     if (selectedSchedule && selectedSchedule.length > 0) {
        //         formik.setFieldValue('startTime', selectedSchedule[0].startTime)
        //         formik.setFieldValue('endTime', selectedSchedule[0].endTime)
        //         formik.setFieldValue('daysOfWeek', selectedSchedule[0].daysOfWeek)
        //     }
        //     setTransientScheduleId(schedId)
        // }

    },[formik.values.organizationId])
    
    return (
        orgsLoading ? (<CircularProgress/>) :
        (<form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
            {showAlert ? (
                <Grid item xs={12}>
                    <Alert severity={alertSeverity}>{alertContent}</Alert>    
                </Grid>
            ) : (<></>)}
                <Grid item xs={12} md={9}>
                    <TextField
                        sx={{m: 1}}
                        id="seriesName"
                        name="seriesName"
                        label="Series Name"
                        fullWidth
                        value={formik.values.seriesName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.seriesName && Boolean(formik.errors.seriesName)}
                        helperText={formik.touched.seriesName && formik.errors.seriesName}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        sx={{m: 1}}
                        id="seriesNumber"
                        name="seriesNumber"
                        label="Series Number"
                        fullWidth
                        value={formik.values.seriesNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.seriesNumber && Boolean(formik.errors.seriesNumber)}
                        helperText={formik.touched.seriesNumber && formik.errors.seriesNumber}/>
                </Grid>                                                                           
                <Grid item xs={12} md={6}>
                    <FormControl sx={{m:1, minWidth:"200px"}}
                    error={formik.touched.organizationId && Boolean(formik.errors.organizationId)}>
                    <InputLabel id="facility-form-organization-id-label">Organization</InputLabel>
                    <Select
                        id="organizationId"
                        name="organizationId"
                        fullWidth
                        labelId="facility-form-organization-id-label"
                        label="Organization"
                        value={formik.values.organizationId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {[...organizations ?? []].sort((a,b) => a.name < b.name ? -1 : 1).map((org) => (
                            <MenuItem value={org.id}>{org.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.organizationId && formik.errors.organizationId}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl sx={{m:1, minWidth:"200px"}}
                    error={formik.touched.facilityId && Boolean(formik.errors.facilityId)}>
                    <InputLabel id="series-form-facility-id-label">Facility</InputLabel>
                    <Select
                        id="facilityId"
                        name="facilityId"
                        fullWidth
                        labelId="series-form-facility-id-label"
                        label="Facility"
                        value={formik.values.facilityId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {[...orgFacilities ?? []].sort((a,b) => a.name < b.name ? -1 : 1).map((fac) => (
                            <MenuItem key={fac.id} value={fac.id}>{fac.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.facilityId && formik.errors.facilityId}</FormHelperText>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <FormControl sx={{m:1, minWidth:"200px"}}
                    error={formik.touched.scheduleId && Boolean(formik.errors.scheduleId)}>
                    <InputLabel id="series-form-schedule-id-label">Schedule</InputLabel>
                    <Select
                        id="scheduleId"
                        name="scheduleId"
                        fullWidth
                        labelId="series-form-schedule-id-label"
                        label="Schedule"
                        value={formik.values.scheduleId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {[...schedules ?? []].sort((a,b) => a.label < b.label ? -1 : 1).map((sched) => (
                            <MenuItem key={sched.id} value={sched.id}>{sched.label}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.scheduleId && formik.errors.scheduleId}</FormHelperText>
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        type="date"
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        type="date"
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        type="time"
                        id="startTime"
                        name="startTime"
                        label="Start Time"
                        value={formik.values.startTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        type="time"
                        id="endTime"
                        name="endTime"
                        label="End Time"
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}/>                    
                </Grid>
                <Grid item xs={12} md={10}>
                <TextField
                        sx={{m: 1}}
                        id="registrationUrl"
                        name="registrationUrl"
                        label="Registration / Website URL"
                        fullWidth
                        value={formik.values.registrationUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.registrationUrl && Boolean(formik.errors.registrationUrl)}
                        helperText={formik.touched.registrationUrl && formik.errors.registrationUrl}/>                    
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        sx={{m: 1}}
                        id="price"
                        name="price"
                        label="Price"
                        type="number"
                        fullWidth
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}/>
                </Grid>   
                <Grid item xs={12}>
                <TextField
                        sx={{m: 1}}
                        id="description"
                        name="description"
                        label="Description"
                        fullWidth
                        multiline
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}/>                    
                </Grid>                                 
                <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard" error={formik.touched.daysOfWeek && Boolean(formik.errors.daysOfWeek)}>
                    <FormLabel component="legend">Days of Week</FormLabel>
                    <FormGroup row>
                        {dayOfWeekOptions.map((opt, index) => (
                            <FormControlLabel
                                key={opt.value}
                                label={opt.label}
                                control={
                                    <Checkbox
                                        name="daysOfWeek" 
                                        value={opt.value}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.daysOfWeek && formik.values.daysOfWeek.some((v) => v===opt.value)}/>
                                }/>
                        ))}
                    </FormGroup>
                    <FormHelperText>{formik.touched.daysOfWeek && formik.errors.daysOfWeek}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl error={formik.touched.daysOfWeek && Boolean(formik.errors.daysOfWeek)}>
                        <FormControlLabel
                            label="Show Sessions for Series Only?"
                            control={
                            <Checkbox
                                name="showSessionsForSeriesOnly"
                                value={formik.values.showSessionsForSeriesOnly ? true : false}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.showSessionsForSeriesOnly}
                                />
                            }></FormControlLabel>
                    </FormControl>
                    <FormHelperText>{formik.touched.showSessionsForSeriesOnly && formik.errors.showSessionsForSeriesOnly}</FormHelperText>
                </Grid>                                                                                
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end">
                        { formik.values.id ? 
                            <Button color="primary" variant="outlined" sx={{m: 1, alignSelf: 'right'}} onClick={handleGenerateSessionsClick}>Generate Sessions</Button>
                            : <></> }
                        <Button color="primary" variant="contained" type="submit" sx={{m: 1, alignSelf: 'right'}}>
                        {formik.values.id ? ('Update') : ('Create')}
                        </Button>
                    </Stack>
                </Grid>                                                                             
            </Grid>
        </form>)
    )
}

export default SeriesForm
