import Dashboard from "./Dashboard";
import FacilityGrid from "../components/facility/FacilityGrid";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useLazyGetFacilityPageQuery, useLazyFindFacilitiesBySearchTermPageQuery } from "../services/facilityApi";
import { useEffect, useState } from "react";
import Paged from "../types/Paged";
import Facility from "../types/Facility";
import { useAppDispatch, useAppSelector, useDebounce } from "../app/hooks";
import { pagination } from "../app/constants";
import ColumnSort from "../types/ColumnSort";
import FacilitiesMap from "../components/facility/FacilitiesMap";
import { selectFacilityPageArgs, setFacilityPageArgs } from "../slices/searchSlice";

const FacilityPage = (props:any) => {

  const pageArgs = useAppSelector(selectFacilityPageArgs)
  const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [facilityPage, setFacilityPage] = useState<Paged<Facility>>(undefined)
    const [getFacilityPage] = useLazyGetFacilityPageQuery()
    const [findFacilitiesBySearchTermPage] = useLazyFindFacilitiesBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(pageArgs.searchTerm, 500)

    useEffect(() => {
      setIsLoading(true)
      getFacilities().then(_ => setIsLoading(false))
    },[debouncedSearchTerm, pageArgs.pageNumber, pageArgs.pageSize, pageArgs.sortBy, pageArgs.sortDirection])    
    
    const getFacilities = async () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        await fetchFacilityPage()
      } else {
        await searchFacilityPage()
      }
    }

    const fetchFacilityPage = async () => {
      const sp = await getFacilityPage(pageArgs)
      setFacilityPage(sp.data)
    }

    const searchFacilityPage = async () => {
      const sp = await findFacilitiesBySearchTermPage(pageArgs)
      setFacilityPage(sp.data)
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      dispatch(setFacilityPageArgs({
        ...pageArgs,
        sortBy: updatedSort.sortBy,
        sortDirection: updatedSort.sortDirection,
        pageNumber: 1
      }))
    }

    const handleSave = () => {
      const args = {...pageArgs}
      console.log('debouncedSearchTerm: ', debouncedSearchTerm)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        args.searchTerm = debouncedSearchTerm
      }
      args.pageNumber = 1

      dispatch(setFacilityPageArgs(args))
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(setFacilityPageArgs({
        ...pageArgs,
        pageNumber: newPage+1
      }))
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFacilityPageArgs({
        ...pageArgs,
        pageSize: +event.target.value,
        pageNumber: 1
      }))
    };
    
    const handleSearch = (searchTerm:string) => {
      const args = {...pageArgs, searchTerm}

      if ((pageArgs.searchTerm && pageArgs.searchTerm.length > 0) && (!searchTerm || searchTerm.length === 0)) {
        args.pageNumber = 1
        args.sortBy = 'name'
        args.sortDirection = 'asc'
      }

      dispatch(setFacilityPageArgs(args))
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <FacilityGrid facilities={facilityPage ? facilityPage.items : []} allowNewFacility={true} showSearchBox={true} afterSave={handleSave} handleSearch={handleSearch} sort={{sortBy: pageArgs.sortBy, sortDirection: pageArgs.sortDirection}} handleSort={handleSort} />
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={facilityPage?.totalItems ?? 0}
                          rowsPerPage={pageArgs.pageSize}
                          page={pageArgs.pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                    
                    </>  }
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <FacilitiesMap facilities={facilityPage ? facilityPage.items : []}></FacilitiesMap>                    
                    </>  }
                </Paper>
              </Grid>              
            </Grid>
        </Dashboard>        
    )
}

export default FacilityPage