import Dashboard from "./Dashboard"
import OrganizationGrid from "../components/organization/OrganizationGrid"
import { CircularProgress, Grid, Paper, TablePagination } from "@mui/material"
import { useEffect, useState } from "react"
import Paged from "../types/Paged"
import Organization from "../types/Organization"
import { useLazyGetOrganizationPageQuery, useLazyFindOrganizationsBySearchTermPageQuery } from "../services/organizationApi"
import { useAppDispatch, useAppSelector, useDebounce } from "../app/hooks"
import { GetPagedArgs } from "../app/queries"
import { pagination } from "../app/constants"
import ColumnSort from "../types/ColumnSort"
import { selectOrganizationPageArgs, setOrganizationPageArgs } from "../slices/searchSlice"

const OrganizationPage = (props:any) => {
    
    const pageArgs = useAppSelector(selectOrganizationPageArgs)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [organizationPage, setOrganizationPage] = useState<Paged<Organization>>(undefined)
    const [getOrganizationPage] = useLazyGetOrganizationPageQuery()
    const [findOrganizationsBySearchTermPage] = useLazyFindOrganizationsBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(pageArgs.searchTerm, 500)        

    const fetchOrganizationPage = async () => {
        const sp = await getOrganizationPage(pageArgs)
        setOrganizationPage(sp.data)
      }
  
      const searchOrganizationPage = async () => {
        const sp = await findOrganizationsBySearchTermPage(pageArgs)
        setOrganizationPage(sp.data)
      }
      
      const handleSort = (sortUpdate:ColumnSort) => {
        dispatch(setOrganizationPageArgs({
          ...pageArgs,
          sortBy: sortUpdate.sortBy,
          sortDirection: sortUpdate.sortDirection,
          pageNumber: 1
        }))
      }

      const handleSave = () => {
      const args = {...pageArgs}
      console.log('debouncedSearchTerm: ', debouncedSearchTerm)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        args.searchTerm = debouncedSearchTerm
      }
      args.pageNumber = 1

      dispatch(setOrganizationPageArgs(args))
      }
  
      const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(setOrganizationPageArgs({
          ...pageArgs,
          pageNumber: newPage+1
        }))
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setOrganizationPageArgs({
          ...pageArgs,
          pageSize: +event.target.value,
          pageNumber: 1
        }))
      };
      
      const handleSearch = (searchTerm:string) => {
      const args = {...pageArgs, searchTerm}

      if ((pageArgs.searchTerm && pageArgs.searchTerm.length > 0) && (!searchTerm || searchTerm.length === 0)) {
        args.pageNumber = 1
        args.sortBy = 'name'
        args.sortDirection = 'asc'
      }

      dispatch(setOrganizationPageArgs(args))
      }
      
      useEffect(() => {
        setIsLoading(true)
        if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
          fetchOrganizationPage().then(_ => setIsLoading(false))
        } else {
          searchOrganizationPage().then(_ => setIsLoading(false))
        }
      },[pageArgs.pageNumber,pageArgs.pageSize,pageArgs.sortBy,pageArgs.sortDirection,debouncedSearchTerm])      
    
    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> :
                        <>
                        <OrganizationGrid organizations={organizationPage ? organizationPage.items : []} afterSave={handleSave} handleSearch={handleSearch} sort={{sortBy: pageArgs.sortBy, sortDirection: pageArgs.sortDirection}} handleSort={handleSort} />
                        <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={organizationPage?.totalItems ?? 0}
                          rowsPerPage={pageArgs.pageSize}
                          page={pageArgs.pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                         
                        </>
                    }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>
    )
}

export default OrganizationPage