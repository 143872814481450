import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GetPagedArgs } from '../app/queries'
import { pagination } from '../app/constants'

interface SearchState {
    facilitySearchTerm?: string,
    facilityPageArgs?: GetPagedArgs,
    seriesSearchTerm?: string,
    seriesPageArgs?: GetPagedArgs,
    organizationSearchTerm?: string,
    organizationPageArgs?: GetPagedArgs,
    userSearchTerm?: string,
    userPageArgs?: GetPagedArgs,
    scheduleSearchTerm?: string 
}

const initialState = {
     facilitySearchTerm: null,
     facilityPageArgs: {
        pageNumber: 1,
        pageSize: pagination.defaultItemsPerPage,
        sortBy: 'name',
        sortDirection: 'asc'
     },
     seriesSearchTerm: null,
     seriesPageArgs: {
        pageNumber: 1,
        pageSize: pagination.defaultItemsPerPage,
        sortBy: 'seriesname',
        sortDirection: 'asc'
     },
     organizationSearchTerm: null,
     organizationPageArgs: {
        pageNumber: 1,
        pageSize: pagination.defaultItemsPerPage,
        sortBy: 'name',
        sortDirection: 'asc'
     },
     userSearchTerm: null,
     userPageArgs: {
        pageNumber: 1,
        pageSize: pagination.defaultItemsPerPage,
        sortBy: 'username',
        sortDirection: 'asc'
     },
     scheduleSearchTerm: null 
} satisfies SearchState as SearchState

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setFacilitySearchTerm: (state, action: PayloadAction<string>) => {
            state.facilitySearchTerm = action.payload
        },
        setFacilityPageArgs: (state, action: PayloadAction<GetPagedArgs>) => {
            state.facilityPageArgs = action.payload
        },
        setSeriesSearchTerm: (state, action: PayloadAction<string>) => {
            state.seriesSearchTerm = action.payload
        },
        setSeriesPageArgs: (state, action: PayloadAction<GetPagedArgs>) => {
            state.seriesPageArgs = action.payload
        },                        
        setOrganizationSearchTerm: (state, action: PayloadAction<string>) => {
            state.organizationSearchTerm = action.payload
        },
        setOrganizationPageArgs: (state, action: PayloadAction<GetPagedArgs>) => {
            state.organizationPageArgs = action.payload
        },
        setUserSearchTerm: (state, action: PayloadAction<string>) => {
            state.userSearchTerm = action.payload
        },
        setUserPageArgs: (state, action: PayloadAction<GetPagedArgs>) => {
            state.userPageArgs = action.payload
        },
        setScheduleSearchTerm: (state, action: PayloadAction<string>) => {
            state.scheduleSearchTerm = action.payload
        }
    },
    selectors: {
        selectFacilitySearchTerm: (state) => {
            return state.facilitySearchTerm
        },
        selectFacilityPageArgs: (state) => {
            return state.facilityPageArgs
        },
        selectSeriesSearchTerm: (state) => {
            return state.seriesSearchTerm
        },
        selectSeriesPageArgs: (state) => {
            return state.seriesPageArgs
        },                              
        selectOrganizationSearchTerm: (state) => {
            return state.organizationSearchTerm
        },
        selectOrganizationPageArgs: (state) => {
            return state.organizationPageArgs
        },
        selectUserSearchTerm: (state) => {
            return state.userSearchTerm
        },
        selectUserPageArgs: (state) => {
            return state.userPageArgs
        },
        selectScheduleSearchTerm: (state) => {
            return state.scheduleSearchTerm
        }
    }
})

export const { 
    setFacilitySearchTerm,
    setFacilityPageArgs,
    setSeriesSearchTerm,
    setSeriesPageArgs,
    setOrganizationSearchTerm,
    setOrganizationPageArgs,
    setUserSearchTerm,
    setUserPageArgs,
    setScheduleSearchTerm
} = searchSlice.actions
export const { 
    selectFacilitySearchTerm,
    selectFacilityPageArgs,
    selectSeriesSearchTerm,
    selectSeriesPageArgs,
    selectOrganizationSearchTerm,
    selectOrganizationPageArgs,
    selectUserSearchTerm,
    selectUserPageArgs,
    selectScheduleSearchTerm 
} = searchSlice.selectors
export default searchSlice