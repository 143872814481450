import { api } from '../app/api'
import User from '../types/User'
import { apiConstants } from '../app/constants'
import Paged from '../types/Paged';
import { GetPagedArgs } from '../app/queries';

const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserPage: builder.query<Paged<User>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection) {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `users/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<User> = {
                    items: _embedded && _embedded.users ? _embedded.users : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        findUsersBySearchTermPage: builder.query<Paged<User>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection) {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `users/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<User> = {
                    items: _embedded && _embedded.users ? _embedded.users : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }              
        }),                
        getAllUsers: builder.query<User[], void>({
            query: () => 'users',
            providesTags: (result) => 
            result ?
                [
                    ...result.map(({id}) => ({ type: 'User', id: id.toString() } as const)),
                    { type: 'User', id: 'LIST' }
                ] :
                [{ type: 'User', id: 'LIST' }],
            transformResponse: ({_embedded}, meta, arg) => {
                return _embedded && _embedded.users ? [..._embedded.users] : []
            }
        }),
        getUserById: builder.query<User, number>({
            query: (id) => `users/${id}`,
            providesTags: (result, error, arg) => result ? [{ type: 'User', id: result.id.toString()}] : [] 
        }),
        createUser: builder.mutation({
            query: (user:User) => {
                const payload = {...user}
                return {
                    url: `users`,
                    method: "POST",
                    body: payload,
                }
            },
            invalidatesTags: (result, error) => [{ type: 'User', id: 'LIST'}]
        }),
        updateUser: builder.mutation({
            query: (user:User) => {
                const payload = {...user}
                return {
                    url: `users/${user.id}`,
                    method: 'PATCH',
                    body: payload,
                    headers: {
                        'Accept': 'application/json',
                    },
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
        }),        
    })
})

export const {
    useLazyGetUserPageQuery,
    useLazyFindUsersBySearchTermPageQuery,
    useGetAllUsersQuery,
    useGetUserByIdQuery,
    useLazyGetUserByIdQuery,
    useCreateUserMutation,
    useUpdateUserMutation
} = userApi