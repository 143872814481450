import { useState } from "react"
import Session from "../../types/Session"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography, Alert, Button, Grid, Stack, TextField, Select, MenuItem, FormControl, FormHelperText, InputLabel, CircularProgress, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useCreateSessionMutation, useUpdateSessionMutation } from "../../services/sessionApi"
import { dayOfWeekOptions } from "../../app/constants"
import { useAuthProvider } from "../../app/context"

type SessionFormProps = { session:Session, afterSave?:Function }
type SessionFormValues = {
    id: number,
    seriesId: number,
    occurrenceDate: string,
    startTime: string,
    endTime: string,
}

const SessionForm = ({session,afterSave}:SessionFormProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState<any>('success')
    const [alertContent, setAlertContent] = useState('')
    const [createSession] = useCreateSessionMutation()
    const [updateSession] = useUpdateSessionMutation()
    const authProvider = useAuthProvider()

    const validationSchema = Yup.object().shape({
        occurrenceDate: Yup.date().required('Date is required'),
        startTime: Yup.string().required('Start time is required'),
        endTime: Yup.string().required('End time is required'),
    })

    const mapValuesToSession = async (values:any) : Promise<Session> => {
        const updateUser = await authProvider.getUser()
        const updateUsername = updateUser.email ?? updateUser.preferred_username

        let sched = {
            id: values.id,
            seriesId: values.seriesId,
            occurrenceDate: values.occurrenceDate,
            startTime: values.startTime,
            endTime: values.endTime,
            lastModified: new Date(),
            modifiedBy: updateUsername,
            status: 'ACTIVE',
            occurrenceStatus: 'CONFIRMED'
        } as Session

        return sched
    }

    const mapSessionToValues = (session:Session) : SessionFormValues => {
        return {
            id: session.id,
            seriesId: session.seriesId,
            occurrenceDate: session.occurrenceDate,
            startTime: session.startTime,
            endTime: session.endTime,
        } as SessionFormValues
    }

    const formik = useFormik({
        initialValues: mapSessionToValues(session),
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    const handleSubmit = async (values:SessionFormValues) => {
        setShowAlert(false)
        setAlertContent('Saving...')
        setAlertSeverity('info')
        setShowAlert(true)
        
        const updateFn = (values.id > 0 ? updateSession : createSession)

        try {
            let updatedSession = await updateFn(await mapValuesToSession(values)).unwrap()
            formik.setValues(updatedSession)
            setAlertContent(`Session saved.`)
            setAlertSeverity('success')
            setShowAlert(true)
            afterSave && afterSave()       
        } catch (error:any) {
            formik.setErrors(error)
            setAlertContent('Error saving session.')
            setAlertSeverity('error')
            setShowAlert(true)             
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
            {showAlert ? (
                <Grid item xs={12}>
                    <Alert severity={alertSeverity}>{alertContent}</Alert>    
                </Grid>
            ) : (<></>)}
                <Grid item xs={12} md={4}>
                <TextField
                        sx={{m: 1}}
                        type="date"
                        id="occurrenceDate"
                        name="occurrenceDate"
                        label="Date"
                        value={formik.values.occurrenceDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.occurrenceDate && Boolean(formik.errors.occurrenceDate)}
                        helperText={formik.touched.occurrenceDate && formik.errors.occurrenceDate}/>                    
                </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                        sx={{m: 1}}
                        type="time"
                        id="startTime"
                        name="startTime"
                        label="Start Time"
                        value={formik.values.startTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}/>                    
                </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                        sx={{m: 1}}
                        type="time"
                        id="endTime"
                        name="endTime"
                        label="End Time"
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}/>                    
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end">
                        <Button color="primary" variant="contained" type="submit" sx={{m: 1, alignSelf: 'right'}}>
                        {formik.values.id ? ('Update') : ('Create')}
                        </Button>
                    </Stack>
                </Grid>                                                                                                    
            </Grid>            
        </form>
    )
}

export default SessionForm